import Component from 'vue-class-component';
import PageRender from '../../models/PageRender';
import { siteService, roleHelper } from '@/main';
import PlaceType from '../../models/PlaceType';
import Vue from 'vue';
import { $router } from '@/router';
import to from 'await-to-js';
import { sitesModule } from '@/store/modules/site';

@Component
export default class AccommodationTypesOverview extends PageRender {
    public isLoading: boolean = true;
    public placeTypes: PlaceType[] = [];
    public gridColumns = [
        { title: 'Naam', cell: this.renderPlaceTypeName },
        { field: 'capacity', title: 'Capaciteit', width: '100px' },
        { field: 'description', title: 'Omschrijving', width: '250px' },
        { field: 'layout', title: 'Indeling', width: '150px' },
        { title: 'Plek type', cell: this.renderPropertyName, width: '150px' },
        { title: 'Acties', cell: this.renderActions, width: '150px' },
    ];

    public async created() {
        await sitesModule.fetchSitesIfNeeded();
        this.placeTypes = (await siteService.getPlaceTypes(this.siteId)).data;
        this.isLoading = false;
    }

    public get site() {
        return sitesModule.activeSite;
    }

    public renderPlaceTypeName(h, a, row): any {
        const item = row ? row.dataItem : {};
        const route = $router.resolve({
            name: 'acco-type',
            params: { siteId: this.siteId.toString(), siteKey: this.siteKey, typeKey: item.placeTypeKey },
        });

        const props = {
            text: item.name,
            url: route.href,
        };

        if (roleHelper.isRecreapiSiteAdmin() || roleHelper.isRecreapiSystemAdmin()) {
            return h(Vue.component('grid-router-link'), { props });
        }

        return h('td', item.name);
    }

    public renderPropertyName(h, a, row): any {
        const item = row ? row.dataItem : {};
        return h('td', item.propertyType.name);
    }

    public renderActions(h, a, row): any {
        const item = row ? row.dataItem : {};
        const actions = [
            { title: 'Bekijk', function: this.goToAccoType },
            { title: 'Bewerk', function: this.editAccoType },
            { title: 'Verwijder', function: this.deleteAccoType },
        ];

        const props = { actions, item };
        return h(Vue.component('grid-actions'), { props });
    }

    public goToAccoType(item: PlaceType): any {
        $router.push({
            name: 'acco-type',
            params: { typeKey: item.placeTypeKey },
        });
    }

    public editAccoType(item: PlaceType): any {
        $router.push({
            name: 'acco-type',
            params: { typeKey: item.placeTypeKey, mode: 'edit' },
        });
    }

    public async deleteAccoType(item) {
        this.showPending('Accommodatietype verwijderen..');
        const [err, response] = await to(siteService.deletePlaceType(item.placeTypeId));
        if (err) {
            return this.clearAndShowError(`Verwijderen mislukt. <br/> ${err} <br/> Het verwijderen is mislukt omdat het accommodatietype geen gekoppelde accommodaties meer mag hebben.`);
        }

        this.clearAndShowSuccess('Accommodatietype verwijderd.');
    }

    public addNewAccomodationType() {
        $router.push({
            name: 'acco-type',
            params: { typeKey: 'aanmaken', mode: 'edit' },
        });
    }
}
